import React from 'react';
import { useLocation } from '@gatsbyjs/reach-router';

import { Nav } from 'components/global/Nav';
import { SEO } from 'components/global/SEO';
import { Footer } from 'components/global/Footer';
import {
  Title,
  InnerBox,
  SectionsBox,
  ListItem,
} from 'components/pages/contactUs/body';
import Section from 'components/pages/contactUs/section';
import { COMPANY_INFO } from 'consts/companyInfo';
import { ArticleCarousel } from 'components/modules/ArticleCarousel';
import { fetchRandomArticles } from 'utils/article/fetchArticles';
import { DecagonChatbot } from 'components/global/DecagonChatbot';

const ContactUsPage = () => {
  const { pathname } = useLocation();
  const randomArticles = fetchRandomArticles(pathname);

  const officesArray = [COMPANY_INFO.sanDiego, COMPANY_INFO.pittsburgh];

  const openChatBot = () => {
    window.duet?.open();
  };

  return (
    <React.Fragment>
      <SEO
        title="Contact Curology | Custom Skincare Products"
        description="Have questions about custom skincare? Chat with a Curology team member today! We’re here to answer any questions you may have."
      />
      <Nav />
      <DecagonChatbot />
      <InnerBox>
        <Title>Contact us</Title>
        <SectionsBox>
          <Section title="We’re here for you. Say hi.">
            <ul>
              <ListItem>
                <a onClick={() => openChatBot()}>Chat with us</a>
              </ListItem>
              <ListItem>
                Text us at{' '}
                <a href={`sms:${COMPANY_INFO.sms}`}>{COMPANY_INFO.sms}</a>
              </ListItem>
            </ul>
          </Section>
          {officesArray.map((office) => (
            <Section key={office.title} title={office.title}>
              <ul>
                <ListItem>{office.company}</ListItem>
                <ListItem>{office.addressLine1}</ListItem>
                <ListItem>{office.addressLine2}</ListItem>
              </ul>
            </Section>
          ))}
        </SectionsBox>
      </InnerBox>
      {!randomArticles?.isLoading && randomArticles?.response && (
        <ArticleCarousel content={randomArticles.response} />
      )}
      <Footer />
    </React.Fragment>
  );
};

export default ContactUsPage;
